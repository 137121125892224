import controller_0 from '@arkounay/ux-collection/dist/controller.js';
import '@arkounay/ux-collection/src/style.css';
import '@arkounay/ux-collection/src/style-when-not-using-bootstrap-5.css';
import controller_1 from '@arkounay/ux-collection/dist/tabbed_controller.js';
import '@arkounay/ux-collection/src/tabbed-style.css';
import controller_2 from '@symfony/ux-autocomplete/dist/controller.js';
import 'tom-select/dist/css/tom-select.default.css';
export default {
  'arkounay--ux-collection--collection': controller_0,
  'arkounay--ux-collection--tabbed-collection': controller_1,
  'symfony--ux-autocomplete--autocomplete': controller_2,
};